import type { FC } from 'react'
import type {
  GetShopItems,
  GetShopItemsVariables,
  GetShopItems_getShopItems,
} from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import type { QueryResult } from '@apollo/client'
import Spinner from '@g4g/ui/src/internal/Spinner'
import { MinimalistButton } from '@g4g/ui/src/internal/buttons/MinimalistButton'
import PlaceholderKorag from '@g4g/ui/src/internal/PlaceholderKorag'
import { useRouter } from 'next/router'
import FrontPageDealsCategory from './FrontPageDealsCategory'
import FrontPageBrowseBtn from './FrontPageBrowseBtn'
import FrontPageBrowseButtons from './FrontPageBrowseButtons'
import {
  largeWideSectionShopItemId,
  popularSectionShopItemIds,
  smallWideSectionShopItemId,
  browseSectionShopItemIds,
  shopOnlySectionShopItemIds,
  shopOnlyWideSectionShopItemId,
} from '@/shop/src/utils/shopItemSections'
import { uniqueShopItems } from '@/shop/src/utils/shopItemUtils'
import { withTotalRewards } from '../../deals/hooks/useFilteredDeals'

const BrowseDealsError: FC = () => {
  const router = useRouter()

  return (
    <PlaceholderKorag
      callToAction={
        <MinimalistButton
          data-cy="shop-reload-page-btn"
          props={{
            mainColor: 'white',
            buttonText: 'Try Again',
            onClick: () => router.reload(),
          }}
        />
      }
    >
      <span className="px-4 text-rose-500">Something went wrong when trying to fetch deals</span>
    </PlaceholderKorag>
  )
}

const LatestDealsSection: FC<{ shopItems: GetShopItems_getShopItems[] }> = ({ shopItems }) => {
  const topRowShopItems = shopItems.filter(({ shopItemId }) =>
    shopOnlySectionShopItemIds().includes(shopItemId)
  )

  if (topRowShopItems.length < 3) {
    return null
  }

  return (
    <>
      <FrontPageDealsCategory
        title="Latest Deals"
        description="Best newly added deals!"
        deals={topRowShopItems.slice(0, 3)}
      />
      <FrontPageDealsCategory
        deals={shopItems.filter(({ shopItemId }) => shopOnlyWideSectionShopItemId() === shopItemId)}
      />
    </>
  )
}

const FrontPageDealsSection: FC<{
  shopItemsRes: QueryResult<GetShopItems, GetShopItemsVariables>
}> = ({ shopItemsRes: { loading, data, error } }) => {
  if (loading) {
    return (
      <div className="flex justify-center w-full mt-4 text-white">
        <Spinner className="w-6 h-6 sm:w-12 sm:h-12" delay={500} />
      </div>
    )
  }

  if (error || !data || !data.getShopItems) {
    return <BrowseDealsError />
  }

  const shopItems = uniqueShopItems(data.getShopItems)

  return (
    <div className="flex flex-col">
      <FrontPageDealsCategory
        title="Exclusive Deals"
        description="The most exclusive deals handpicked for you!"
        data-cy="shop-deals-large-list"
        large
        deals={shopItems.slice(0, 2)}
      />
      <FrontPageDealsCategory
        large
        deals={shopItems.filter(({ shopItemId }) => largeWideSectionShopItemId() === shopItemId)}
      />
      <LatestDealsSection shopItems={shopItems} />
      <FrontPageDealsCategory
        title="Most Popular This Week"
        description="What's hot this week"
        deals={shopItems
          .filter(({ shopItemId }) => popularSectionShopItemIds().includes(shopItemId))
          .slice(0, 3)}
      />
      <FrontPageDealsCategory
        deals={shopItems.filter(({ shopItemId }) => smallWideSectionShopItemId() === shopItemId)}
      />
      <FrontPageBrowseButtons />
      <FrontPageDealsCategory
        deals={uniqueShopItems(
          withTotalRewards(
            data.getShopItems.filter(({ shopItemId }) =>
              browseSectionShopItemIds().includes(shopItemId)
            )
          )
        ).sort(
          ({ shopItemId: a }, { shopItemId: b }) =>
            browseSectionShopItemIds().indexOf(a) - browseSectionShopItemIds().indexOf(b)
        )}
      />
      <div className="flex w-full mt-8 sm:hidden">
        <FrontPageBrowseBtn href="/deals" text="View Deals" />
      </div>
    </div>
  )
}

export default FrontPageDealsSection
