import { FC, useEffect, useState } from 'react'
import {
  getMillisRemainingToWeeklyReset,
  getMillisRemainingToMonthlyReset,
} from '@g4g/utils/src/timeUtils'
import { useInterval } from 'react-use'
import { calculateDaysHoursMinsSecs } from '@g4g/utils/src/timeUtils'
import { classNames } from '@g4g/utils/src/react/class-names'

const Piece = ({ time, unit }: { time: number; unit: string }) => (
  <div
    data-cy="shop-deal-countdown"
    data-cy-spec={unit}
    className={classNames(
      'flex w-16 h-16 rounded bg-neutral-900/40',
      'items-center justify-center flex-col'
    )}
  >
    <span className="block text-3xl">{time > 0 ? time.toString().padStart(2, '0') : '00'}</span>
    <span className="text-xs uppercase">{unit}</span>
  </div>
)

const Countdown: FC<{
  millis: number
}> = ({ millis }) => {
  const [countdown, setCountdown] = useState(millis)

  useInterval(() => {
    setCountdown((c) => c - 1000)
  }, 1000)

  // React to prop changes.
  useEffect(() => {
    setCountdown(millis)
  }, [millis])

  const { days, hours, mins, secs } = calculateDaysHoursMinsSecs(countdown)

  return (
    <div className="relative flex space-x-2" data-cy="shop-hero-countdown">
      <Piece time={days} unit="days" />
      <Piece time={hours % 24} unit="hours" />
      <Piece time={mins % 60} unit="minutes" />
      <Piece time={secs % 60} unit="seconds" />
    </div>
  )
}

const ShopItemWeeklyCountdown: FC = () => {
  // Need to do this or else server side will give different html compared to client.
  const [millis, setMillis] = useState(0)

  useEffect(() => {
    setMillis(getMillisRemainingToWeeklyReset())
  }, [])

  return <Countdown millis={millis} />
}

const ShopItemMonthlyCountdown: FC = () => {
  // Need to do this or else server side will give different html compared to client.
  const [millis, setMillis] = useState(0)

  useEffect(() => {
    setMillis(getMillisRemainingToMonthlyReset())
  }, [])

  return <Countdown millis={millis} />
}

const ShopItemCountdown: FC<{
  period?: 'weekly' | 'monthly'
}> = ({ period = 'weekly' }) => {
  if (period === 'weekly') {
    return <ShopItemWeeklyCountdown />
  }

  return <ShopItemMonthlyCountdown />
}

export default ShopItemCountdown
