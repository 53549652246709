import type { FC } from 'react'
import ShopPageLayout from '../../layout/ShopPageLayout'
import FrontPageSidebar from '../FrontpageSidebar'
import Hero from '../hero/Hero'
import FrontPageDealsSection from './FrontPageDealsSection'
import ExclusiveShopBonus from './ExclusiveShopBonus'
import { useActiveRecipientShopItems } from '../../shared/hooks/useActiveRecipientShopItems'

const FrontPageDeals: FC = () => {
  const res = useActiveRecipientShopItems()

  return (
    <div className="flex flex-col">
      <Hero shopItemsRes={res} />
      <ShopPageLayout
        containerClass="pt-6 sm:pt-10 mb-12"
        leftSideContent={<FrontPageSidebar />}
        rightSideContent={
          <div className="flex flex-col space-y-6 sm:space-y-12">
            <ExclusiveShopBonus />
            <FrontPageDealsSection shopItemsRes={res} />
          </div>
        }
      />
    </div>
  )
}

export default FrontPageDeals
