import type { FC } from 'react'
import SideFilter from '../shared/SideFilter'
import { tickFilters } from '@g4g/utils/src/gameDataUtils'

const { category, resource } = tickFilters()

const FrontPageSidebar: FC = () => (
  <>
    <SideFilter trait="category" filters={category} isLink />
    <div className="py-8">
      <SideFilter trait="resource" filters={resource} isLink />
    </div>
  </>
)

export default FrontPageSidebar
