import type { FC, ReactNode } from 'react'
import type { GetShopItems_getShopItems } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import LargeDealsList from '../../shared/deal-thumbnails/square/LargeDealsList'
import StandardDealsList from '../../shared/deal-thumbnails/square/StandardDealsList'
import Category from '../Category'
import LargeSquareThumbnail from '../../shared/deal-thumbnails/square/LargeSquareThumbnail'
import StandardSquareThumbnail from '../../shared/deal-thumbnails/square/StandardSquareThumbnail'
import { classNames } from '@g4g/utils/src/react/class-names'

const Section: FC<{
  wrapperClassNames?: string
  children?: ReactNode
  'data-cy'?: string
  title?: string
  description?: string
  hideViewBtn?: boolean
}> = ({ wrapperClassNames, children, description, title, 'data-cy': dataCy, hideViewBtn }) => (
  <div className={classNames('w-full', wrapperClassNames)} data-cy={dataCy}>
    <Category hideViewBtn={hideViewBtn} title={title} description={description}>
      {children}
    </Category>
  </div>
)

const FrontPageDealsCategory: FC<{
  deals: GetShopItems_getShopItems[]
  title?: string
  description?: string
  large?: boolean
  'data-cy'?: string
  children?: ReactNode
}> = ({ deals, large, title, description, 'data-cy': dataCy, children }) => {
  // A custom content section without deals
  // i.e. browse shop buttons
  if (deals.length === 0) {
    return (
      <Section
        wrapperClassNames="md:mb-6 lg:mb-8"
        hideViewBtn
        title={title}
        description={description}
        data-cy={dataCy}
      >
        {children}
      </Section>
    )
  }

  // A wide tile expected
  if (deals.length === 1) {
    const [shopItem] = deals
    return (
      <Section
        wrapperClassNames="xl:pl-6 mt-4 lg:mt-4 mb-6 lg:mb-12"
        title={title}
        description={description}
        data-cy={dataCy}
      >
        {large ? (
          <LargeSquareThumbnail stretched shopItem={shopItem} />
        ) : (
          <StandardSquareThumbnail stretched shopItem={shopItem} />
        )}
      </Section>
    )
  }

  return (
    <Section
      wrapperClassNames="lg:mb-6 md:mb-4"
      title={title}
      description={description}
      data-cy={dataCy}
    >
      {large ? <LargeDealsList items={deals} /> : <StandardDealsList items={deals} />}
    </Section>
  )
}
export default FrontPageDealsCategory
