import { __DEV__ } from '@g4g/utils/src/react/environment'
import { usePreviousDistinct, useUpdateEffect } from 'react-use'
import {
  useAddToCartConfirmation,
  useAddToCartConfirmationOpsDispatch,
} from '../confirmation/AddToCartConfirmationProvider'
import { useShoppingCart } from '../ShoppingCartProvider'

/**
 * Hook this up in a component with the <AddToCart />
 * Button and add the <AddToCartConfirmation /> to the
 * target route page to show the confirmation.
 *
 */
export function useAddToCartConfirmationTrigger(
  shopItemId: string,
  // true will trigger confirmation even though the
  // items haven't changed
  force?: boolean
) {
  const items = useShoppingCart()
  const previousItems = usePreviousDistinct(items)
  const dispatch = useAddToCartConfirmationOpsDispatch()
  const { loading } = useAddToCartConfirmation()

  useUpdateEffect(() => {
    const condition = __DEV__
      ? // Fixes hot reloads on dev.
        previousItems && JSON.stringify(previousItems) !== JSON.stringify(items)
      : previousItems
    if (
      condition ||
      // Loading is set to true when a deal has been added.
      (loading && force)
    ) {
      const isUpdate = (previousItems || []).some((i) => i.shopItemId === shopItemId)
      // Set the added shop item id, this one should be displayed in
      // the confirmation
      dispatch({ type: 'added-confirm', addedShopItemId: shopItemId, wasUpdate: isUpdate })
    }
  }, [items, previousItems, shopItemId, dispatch, loading, force])
}
