import type { FC } from 'react'
import type { GetShopItems_getShopItems } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import ShopItemImage from '../../shared/ShopItemImage'
import PrpsDubiPrice from '../../shared/PrpsDubiPrice'
import CartButton from '../../shopping-cart/buttons/CartButton'
import { cartContainsItem, toShoppingCartItem } from '../../shopping-cart/util/cart'
import { exclusiveModelType, verificationRequired } from '@/shop/src/utils/shopItemUtils'
import { useAddToCartConfirmationTrigger } from '../../shopping-cart/hooks/useAddToCartConfirmationTrigger'
import { useShoppingCart } from '../../shopping-cart/ShoppingCartProvider'
import ViewCart from '../../shopping-cart/buttons/ViewCart'
import ShopItemCountdown from '../../shared/ShopItemCountdown'
import { Faction } from '@g4g/graphql/src/shop/__generated__/globalTypes'
import FactionTheme from '../../shared/FactionTheme'
import { ShoppingCartItem } from '../../shopping-cart/provider/types'
import AddedToCartIcon from '../../shared/AddedToCartIcon'
import ShopItemLink from '../../shared/ShopItemLink'
import Spinner from '@g4g/ui/src/internal/Spinner'
import UnavailableBadge from '../../shared/deal-thumbnails/badges/UnavailableBadge'
import { classNames } from '@g4g/utils/src/react/class-names'

const BestDealPrice: FC<{ shopItem: GetShopItems_getShopItems }> = ({ shopItem }) => (
  <PrpsDubiPrice
    parentClassName="pb-2"
    textClassSize="text-xl"
    prices={shopItem.lootRewardPackage.productPrice}
    discount={shopItem.discount}
    showDiscountPercent={true}
    tokenLogoClass="w-7 h-7"
  />
)

const BuyNowButton: FC<{
  disabled: boolean
  cartItem: ShoppingCartItem
}> = ({ cartItem, disabled }) => (
  <CartButton
    action={{
      buyNow: true,
      type: 'add',
      products: cartItem,
    }}
    disabled={disabled}
    color="tertiary"
    className="w-full md:w-[200px]"
    icon="bolt"
  >
    Buy now
  </CartButton>
)

const CartActionButtons: FC<{ shopItem: GetShopItems_getShopItems }> = ({ shopItem }) => {
  const cartItems = useShoppingCart()
  const cartItem = toShoppingCartItem({ product: shopItem, totalQuantity: 1 })
  const notVerified = verificationRequired(shopItem)

  return (
    <div className="flex space-x-2">
      {cartContainsItem(shopItem, cartItems) ? (
        <ViewCart className="w-full md:w-[200px]" />
      ) : (
        <CartButton
          disabled={notVerified}
          color="primary"
          className="w-full md:w-[200px]"
          icon="cart"
          action={{
            type: 'add',
            products: cartItem,
          }}
        >
          Add to cart
        </CartButton>
      )}
      <BuyNowButton disabled={notVerified} cartItem={cartItem} />
    </div>
  )
}

const AddedToCart: FC<{ shopItem: GetShopItems_getShopItems }> = ({ shopItem }) => {
  const cartItems = useShoppingCart()

  return (
    <>
      {cartContainsItem(shopItem, cartItems) && (
        <AddedToCartIcon iconsClassNames="w-5 h-5 md:w-6 md:h-6" />
      )}
    </>
  )
}

const NftCardAddToCart: FC<{ shopItem: GetShopItems_getShopItems }> = ({ shopItem }) => {
  useAddToCartConfirmationTrigger(shopItem.shopItemId)

  return (
    <>
      <div className="flex items-center space-x-1">
        <BestDealPrice shopItem={shopItem} />
      </div>
      <CartActionButtons shopItem={shopItem} />
    </>
  )
}

const NftCard: FC<{ shopItem?: GetShopItems_getShopItems; loading: boolean }> = ({
  shopItem,
  loading,
}) => {
  const faction = shopItem?.faction || Faction.None
  const userVerificationRequired = shopItem && verificationRequired(shopItem)

  return (
    <div
      // Note: the bg is set here for mobile and in "right col" for desktop.
      className={classNames(
        'flex flex-col items-center justify-center px-4 pb-4',
        'mx-auto rounded-lg bg-neutral-800/80 md:px-0 md:flex-row',
        'lg:mx-24 md:bg-transparent md:pb-0 relative',
        userVerificationRequired && 'pt-7 md:pt-0'
      )}
      data-cy="shop-hero-card-wrapper"
    >
      {loading ? (
        <div className="w-[200px] h-[270px] md:w-[260px] md:h-[350px] flex justify-center items-center md:bg-neutral-800/80">
          <Spinner className="w-12 h-12 text-white" delay={500} />
        </div>
      ) : (
        <ShopItemLink
          className="flex shrink-0 flex-col justify-center items-start relative w-[200px] h-[270px] md:w-[260px] md:h-[350px] overflow-hidden"
          kebabOrShopItem={shopItem ?? 'exclusive-skin-boost'}
        >
          {shopItem && (
            <ShopItemImage
              shopItem={shopItem}
              type="front"
              fill
              sizes="100vw"
              className="object-cover"
            />
          )}
        </ShopItemLink>
      )}
      {userVerificationRequired && (
        <UnavailableBadge right large>
          Verification required
        </UnavailableBadge>
      )}
      {/* Right col */}
      <div className="flex flex-col text-white rounded-lg shadow-inner font-poppins md:py-6 md:px-8 md:-ml-4 md:bg-neutral-800/80">
        {/* Use the <Unavailable badge, based on shopItem?.availability?.uVerified */}
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold md:text-3xl md:mt-0 [text-shadow:0_4px_8px_rgba(0,0,0)]">
            Exclusive{' '}
            {shopItem && (
              <FactionTheme faction={faction} className="inline-block">
                {exclusiveModelType(shopItem)} Boost!
              </FactionTheme>
            )}
          </h1>
          {shopItem && <AddedToCart shopItem={shopItem} />}
        </div>
        <div className="flex flex-col">
          <p className="mt-1 mb-2 text-sm md:text-base">
            Available for a limited time and never returns again!
          </p>
          {/* Keep the height fixed for no layout shifts */}
          <div className="flex flex-col w-full h-24 space-y-2">
            {shopItem && <NftCardAddToCart shopItem={shopItem} />}
          </div>
        </div>
        <div className="flex flex-col justify-end grow md:mb-0">
          <p className="mb-1 font-medium">Ends in:</p>
          <FactionTheme faction={faction}>
            <ShopItemCountdown />
          </FactionTheme>
        </div>
      </div>
    </div>
  )
}

export default NftCard
