import type { FC } from 'react'
import IDealThumbnailProps from '../thumbnailProps'
import DealThumbnailLoot from '../DealThumbnailLoot'
import PrpsDubiPrice from '../../PrpsDubiPrice'
import SquareThumbnail from './SquareThumbnail'
import ThumbnailInfoPanel from '../ThumbnailInfoPanel'
import { classNames } from '@g4g/utils/src/react/class-names'
import { PurchaseCategory } from '@g4g/graphql/src/shop/__generated__/globalTypes'
import AddedToCartIcon from '../../AddedToCartIcon'

const LargeSquareThumbnail: FC<IDealThumbnailProps> = (props) => {
  let thumbnailInfoPanel
  if (props.shopItem) {
    const prices =
      props.shopItem.purchaseCategory === PurchaseCategory.Bundle &&
      props.shopItem.lootRewardPackage.bundlePrice?.price
        ? props.shopItem.lootRewardPackage.bundlePrice
        : props.shopItem.lootRewardPackage.productPrice
    thumbnailInfoPanel = (
      <ThumbnailInfoPanel
        stretched={props.stretched}
        topLeft={
          <div className="flex items-center justify-between w-full">
            <h4 className="text-lg text-white font-poppins sm:text-xl xl:text-2xl limitLines limitOneLine">
              {props.shopItem.name}
            </h4>
            {props.addedToCart && <AddedToCartIcon iconsClassNames="w-4 h-4 xl:w-6 xl:h-6" />}
          </div>
        }
        bottomLeft={
          <PrpsDubiPrice
            crossFade
            textClassSize="text-md sm:text-lg"
            prices={prices}
            showDiscountPercent={true}
            discountPercentClass="text-sm"
            discount={props.shopItem.discount}
            tokenLogoClass="w-6 h-6 sm:w-7 sm:h-7"
          />
        }
      />
    )
  }

  return (
    <SquareThumbnail
      {...props}
      iconSize={280}
      large={true}
      shopItemIconClassName=""
      dealThumbnailLoot={
        <>
          {!!props.shopItem?.lootRewardPackage?.lootRewards && (
            <div
              className={classNames(
                props.stretched &&
                  'md:right-1/4 md:bottom-auto md:pt-4 md:h-full md:items-center md:pr-14 xl:pr-32',
                props.stretched ? 'justify-center md:justify-end' : 'justify-center',
                'absolute left-0 bottom-0 right-0 flex'
              )}
            >
              <DealThumbnailLoot
                stretched={props.stretched}
                lootRewards={props.shopItem.lootRewardPackage.lootRewards}
                responsive
              />
            </div>
          )}
        </>
      }
      thumbnailInfoPanel={thumbnailInfoPanel}
    />
  )
}

export default LargeSquareThumbnail
