import type { FC } from 'react'
import { tickFilters } from '@g4g/utils/src/gameDataUtils'
import FrontPageDealsCategory from './FrontPageDealsCategory'
import FrontPageBrowseBtn from './FrontPageBrowseBtn'

const { category } = tickFilters()

const FrontPageBrowseButtons: FC = () => (
  <>
    <div className="flex w-full mt-4 mb-6 sm:hidden">
      <FrontPageBrowseBtn href="/deals" text="View Deals" />
    </div>
    <FrontPageDealsCategory title="Browse Shop" deals={[]}>
      <div className="hidden w-full pb-8 space-x-4 md:flex xl:pl-6">
        {category.map((c) => (
          <FrontPageBrowseBtn
            tertiary
            fill
            key={c.id}
            href={`/deals/?category=${c.id}`}
            text={<span style={{ color: 'white' }}>{c.name}</span>}
          />
        ))}
      </div>
    </FrontPageDealsCategory>
  </>
)

export default FrontPageBrowseButtons
