import type { ReactElement } from 'react'
import Meta from '@g4g/ui/src/internal/head/Meta'
import FrontPageDeals from '../components/frontpage/browse/FrontPageDeals'

export default function Home() {
  return (
    <>
      <Meta
        app="shop"
        title="Mobile Minigames Shop - Get In-Game Deals for PRPS and DUBI"
        canonical="https://shop.mobileminigames.com"
        socialMediaImage={
          (process.env.NEXT_PUBLIC_SHOP_APP_URL ?? 'https://shop.mobileminigames.com') +
          '/shop_app_social.jpg'
        }
        socialMediaImageAlt="Mobile Minigames Shop"
      />
      <FrontPageDeals />
    </>
  )
}

Home.getLayout = function getLayout(page: ReactElement) {
  // The home page only has the global layout
  return <div>{page}</div>
}
