import type { FC, ReactNode } from 'react'
import { MinimalistButton } from '@g4g/ui/src/internal/buttons/MinimalistButton'

const BrowseBtn: FC<{
  href: string
  text: ReactNode
  'data-cy'?: string
  fill?: boolean
  tertiary?: boolean
}> = ({ href, text, 'data-cy': dataCy, fill, tertiary }) => (
  <MinimalistButton
    // !px is to fit the text on Browse Shop Category into one
    // line, for the 5 buttons in a row
    className="h-11 !px-1"
    data-cy={dataCy}
    props={{
      full: true,
      mainColor: tertiary ? '#075984' : '#05bb00',
      buttonText: text,
      href,
      as: 'link',
      fill,
    }}
  />
)

export default BrowseBtn
