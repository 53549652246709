import type { FC } from 'react'
import { useShoppingCart } from '../ShoppingCartProvider'
import CartButton from '../buttons/CartButton'
import { cartItemsCount } from '../util/cart'

const ViewCart: FC<{ color?: 'primary' | 'secondary'; className?: string }> = ({
  color = 'primary',
  className,
}) => {
  const items = useShoppingCart()

  return (
    <CartButton
      className={className}
      data-cy="shop-add-to-cart-confirmation-view-cart"
      href="/cart"
      color={color}
    >
      <span>View cart</span>
      <span className="sr-only sm:not-sr-only">
        <span data-cy="shop-add-to-cart-confirmation-count">
          {' ('}
          {cartItemsCount(items)}{' '}
        </span>
        <span>items{')'}</span>
      </span>
    </CartButton>
  )
}

export default ViewCart
