import type { FC } from 'react'
import type {
  GetShopItems,
  GetShopItemsVariables,
} from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import type { QueryResult } from '@apollo/client'
import NftCard from './NftCard'
import ShopItemImage from '../../shared/ShopItemImage'
import { getProduct } from '../../shopping-cart/util/cart'
import { SkinShopItemId } from '@g4g/utils/src/types'

const Hero: FC<{
  shopItemsRes: QueryResult<GetShopItems, GetShopItemsVariables>
}> = ({ shopItemsRes: { data, loading } }) => {
  const shopItem = getProduct(SkinShopItemId.ExclusiveModelBoost, data?.getShopItems)

  return (
    // bg-color separates the Hero section with color as a
    // fallback, if shopItem assets are not being loaded
    <div className="relative bg-gray-1000 font-poppins tracking-normal leading-normal sm:[mask-image:linear-gradient(to_bottom,gray_90%,transparent_100%)]">
      {shopItem && (
        <ShopItemImage
          priority
          shopItem={shopItem}
          type="bg-frontpage"
          className="blur-xxs brightness-75 object-cover object-left-top"
          fill
          sizes="100vw"
          placeholder="blur"
        />
      )}
      <div className="container relative flex flex-col items-center w-full md:flex-row">
        <div className="flex flex-col items-center w-full my-4 sm:mt-8 sm:mb-24">
          <NftCard shopItem={shopItem} loading={loading} />
        </div>
      </div>
    </div>
  )
}

export default Hero
