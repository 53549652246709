import type { FC, ReactNode } from 'react'
import { MinimalistButton } from '@g4g/ui/src/internal/buttons/MinimalistButton'
import { StaticImageData } from 'next/image'
import Image from 'next/image'
import PrpsImg from '@/shop/public/shared-assets/img/prps.png'
import DubiImg from '@/shop/public/shared-assets/img/dubi.png'
import { getAddress } from '@g4g/wallet-modal/util/address'
import { SupportedNetworkIDs, CallableContractNames, ERC20Names } from '@g4g/wallet-modal/types'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { classNames } from '@g4g/utils/src/react/class-names'

const DiscountIcon: FC<{
  img: StaticImageData
  name: ERC20Names
}> = ({ img, name }) => (
  <div className="relative flex items-center justify-center">
    <div className="w-[60px] h-[60px] xl:w-[80px] xl:h-[80px]">
      <Image height={80} width={80} src={img} alt="Exclusive shop bonus" />
    </div>
    <div
      className={classNames(
        'absolute z-40 w-full text-2xl md:text-3xl text-green-400',
        'font-poet cos-standard-text-shadow 2xl:text-4xl -bottom-1',
        name === 'Purpose' ? '-right-5 2xl:-right-10' : '-right-4 2xl:-right-7'
      )}
    >
      <div className="flex items-center justify-center w-full h-full">
        {name === 'Purpose' ? '+30%' : '+160%'}
      </div>
    </div>
  </div>
)

const Btn: FC<{ text: string; tokenName: CallableContractNames }> = ({ text, tokenName }) => (
  <MinimalistButton
    className="h-11"
    target="_blank"
    href={`https://info.quickswap.exchange/#/token/${getAddress(
      tokenName,
      SupportedNetworkIDs.Mainnet
    )}`}
    fill
    textClassSize="text-sm md:text-lg"
    full
    buttonText={text}
    mainColor="#00d1ff"
    icon={<ArrowTopRightOnSquareIcon className="order-2 w-4 h-4 ml-1" />}
  />
)

const Reward: FC<{
  img: StaticImageData
  children?: ReactNode
  name: ERC20Names
}> = ({ img, name, children }) => (
  <div
    data-cy="shop-exclusive-bonus-discount"
    className="relative flex flex-col items-center w-1/2 py-1 mt-4 mb-2 sm:mt-0 lg:flex-1"
  >
    <DiscountIcon img={img} name={name} />
    <div className="flex items-center justify-start z-1 sm:justify-start xl:px-2">{children}</div>
  </div>
)

const ExclusiveShopBonus: FC = () => (
  <div className="xl:pl-6 font-poppins">
    <div className="flex flex-col justify-between p-4 rounded-lg sm:space-x-8 sm:px-6 sm:pt-6 sm:pb-4 sm:flex-row xl:space-x-24 bg-sky-800">
      {/* Left/top */}
      <div className="flex flex-col sm:w-1/2">
        <h2 className="text-xl font-bold text-white sm:mb-4 xl:text-3xl">Exclusive Shop Bonus!</h2>
        <p className="text-sm leading-5 xl:leading-6 sm:text-md xl:text-base text-white/80">
          All deals on the Mobile Minigames Shop can be acquired by burning{' '}
          <a
            href="https://prps.io"
            target="_blank"
            className="font-bold cursor-pointer text-cyan-300 hover:text-cyan-200"
            rel="noreferrer"
          >
            PRPS or DUBI
          </a>{' '}
          for better value than what is found in-game.
        </p>
        <div className="flex items-center pt-1">
          <span className="mt-1 mb-0 mr-2 text-xs font-normal text-white/80">Video Guide: </span>
          <a
            className="text-white/80 hover:text-white"
            href="https://www.youtube.com/watch?v=c_5z8IPJpQI"
            target="_blank"
            rel="noopener noreferrer"
          >
            <u className="text-xs font-normal text-underline">How To Get PRPS & DUBI</u>
          </a>
        </div>
      </div>
      {/* Right/bottom */}
      <div className="relative flex flex-col sm:w-1/2">
        <div className="flex w-full space-x-2">
          <Reward img={PrpsImg} name="Purpose">
            <div className="text-xs font-bold text-white md:text-sm 2xl:text-lg cos-standard-text-shadow">
              Bonus PRPS Value!
            </div>
          </Reward>
          <Reward img={DubiImg} name="Dubi">
            <div className="text-xs font-bold text-white md:text-sm 2xl:text-lg cos-standard-text-shadow">
              Bonus DUBI Value!
            </div>
          </Reward>
        </div>

        <div className="flex space-x-4 min-w-[220px]">
          <Btn text="Get PRPS" tokenName="Purpose" />
          <Btn text="Get DUBI" tokenName="Dubi" />
        </div>

        <p className="flex justify-end w-full mt-2 text-xs text-white/80">
          {'* '}bonus value may be higher depending on current market prices
        </p>
      </div>
    </div>
  </div>
)

export default ExclusiveShopBonus
