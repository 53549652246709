import type { FC } from 'react'
import { GetShopItems_getShopItems } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import { cartContainsItem } from '../../../shopping-cart/util/cart'
import LargeSquareThumbnail from './LargeSquareThumbnail'
import { classNames } from '@g4g/utils/src/react/class-names'
import { useShoppingCart } from '../../../shopping-cart/ShoppingCartProvider'

// TODO Cleanup: hopefully we can get rid of this "LargeDealsList" component all together.
const LargeDealsList: FC<{ items: (GetShopItems_getShopItems | undefined)[] }> = ({ items }) => {
  const cartItems = useShoppingCart()

  return (
    <>
      {items.map((item, i) => {
        return (
          <LargeSquareThumbnail
            key={item?.shopItemId || i}
            loading={item === undefined}
            parentClassName={classNames(
              'w-full md:w-1/2 md:odd:pr-2 md:even:pl-2 md:pb-4',
              'xl:pl-6 xl:even:pl-6 xl:odd:pr-0 xl:pb-6'
            )}
            addedToCart={item && cartContainsItem(item, cartItems)}
            shopItem={item}
          />
        )
      })}
    </>
  )
}

export default LargeDealsList
