import type { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'
import { Faction } from '@g4g/graphql/src/shop/__generated__/globalTypes'

const colors = {
  [Faction.Kappa]: '#fff320',
  [Faction.KappaPride]: '#fd70e8',
  [Faction.LuL]: '#77d0f2',
  [Faction.PogChamp]: '#fe991b',
  [Faction.SMOrc]: '#3ef73b',
  [Faction.TriHard]: 'rgb(221, 180, 253)',
  [Faction.None]: 'rgb(255, 255, 255)',
}

function getFactionColor(faction: Faction) {
  return colors[faction] || 'whitesmoke'
}

type SpanProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>

/** Colors text to given faction. */
const FactionTheme: FC<{ children: ReactNode; faction: Faction } & SpanProps> = ({
  children,
  faction,
  style,
  ...rest
}) => (
  <span
    style={{
      ...style,
      color: getFactionColor(faction),
    }}
    {...rest}
  >
    {children}
  </span>
)

export default FactionTheme
