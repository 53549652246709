import type { FC, ReactNode } from 'react'
import FrontPageBrowseBtn from './browse/FrontPageBrowseBtn'

const Category: FC<{
  title?: string
  description?: string
  dealsBtn?: boolean
  children?: ReactNode
  hideViewBtn?: boolean
}> = ({ title, description, children, hideViewBtn }) => (
  <>
    {title && (
      <div className="flex flex-col-reverse mb-6 sm:flex-row xl:ml-6">
        <div className="flex flex-col grow">
          <h2 className="text-2xl text-white font-poppins">{title}</h2>
          {description && <p className="text-sm text-gray-400 font-poppins">{description}</p>}
        </div>
        {!hideViewBtn && (
          <div className="flex w-full mt-2 mb-6 sm:my-0 sm:max-w-[150px]">
            <FrontPageBrowseBtn data-cy="shop-view-deals-button" href="/deals" text="View Deals" />
          </div>
        )}
      </div>
    )}
    <div className="flex flex-wrap w-full space-y-4 md:space-y-0">{children}</div>
  </>
)

export default Category
